module.exports = {
    loadHero: function () {
		setTimeout(function () {
			if (/hero-chat=([^&]+)/.exec(window.location.href)) {
				if (hero) {
				    hero('show');
				}
			}
		}, 1000);
    }
};
$(window).on('load', function () {
    if ($('.set-carousel').length > 0) {
        $('.set-carousel').slick({
            slidesToShow: 1,
            dots: true,
            infinite: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        dots: true,
                        infinite: true,
                        variableWidth: true
                    }
                }
            ]
        });
    }
});

module.exports = function calculateCountdown(dateTime) {
    var currentDateTime = new Date().getTime();
    var promotionCountdown = new Date(dateTime).getTime();
    var timeDifference = promotionCountdown - currentDateTime;

    if (timeDifference <= 60000) {
        return;
    }

    var dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    var hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60) % 24);
    var minutesDifference = Math.floor(timeDifference / (1000 * 60) % 60);

    return {
        dayDifference,
        hoursDifference,
        minutesDifference
    };
}
'use strict';

/**
 * Function to update image attributes
 * @param {JQuery} $element - Jquery element to check
 * @returns {boolean} - Returns true if element is in viewport, else returns false
 */
function isInViewport($element) {
    let elementTop = $element.offset().top;
    let elementBottom = elementTop + $element.outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

module.exports = {
    isInViewport: isInViewport
};
'use strict';
var focusHelper = require('base/components/focus');
/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    // modal element
    var $trackingConsent = $('.tracking-consent');

    if (!$trackingConsent.data('caonline')) {
        return;
    }

    var urlContent = $trackingConsent.data('url');
    var urlAccept = $trackingConsent.data('accept');
    var urlReject = $trackingConsent.data('reject');
    var textYes = $trackingConsent.data('accepttext');
    var textNo = $trackingConsent.data('rejecttext');
    var textHeader = $trackingConsent.data('heading');

    var htmlString = `
        <div class="modal modal-center consent-tracking" 
            id="consent-tracking" role="dialog" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">${textHeader}</div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                        <div class="button-wrapper">
                            <button class="decline btn btn-block button-secondary" 
                                data-url="${urlReject}">${textNo}</button>
                            <button class="affirm btn btn-block button-primary" 
                                data-url="${urlAccept}">${textYes}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function(response) {
            $('.consent-tracking .modal-body').html(response);
            $('#consent-tracking').modal({
                show: true,
                backdrop: 'static'
            });
            $('body').addClass('consent-tracking-modal-open');
        },
        error: function() {
            $('#consent-tracking').remove();
        }
    });

    $('#consent-tracking .button-wrapper button').click(function(e) {
        e.preventDefault();
        var url = $(this).data('url');

        var $consentTrackingSpinner = $('#consent-tracking').spinner();
        $consentTrackingSpinner.start();

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function() {
                //$('#consent-tracking').remove();
                $consentTrackingSpinner.stop();
                $('#consent-tracking').modal('hide');
                $('body').removeClass('consent-tracking-modal-open');
            },
            error: function() {
                //$('#consent-tracking').remove();
                $consentTrackingSpinner.stop();
                $('#consent-tracking').modal('hide');
                $('body').removeClass('consent-tracking-modal-open');
            }
        });
    });

    $('#consent-tracking').on('hidden.bs.modal', function(e) {
        $('body').removeClass('consent-tracking-modal-open');
    });

    $('body').on('keydown', '#consent-tracking', function(e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: 'button.decline',
            lastElementSelector: 'button.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
}

module.exports = function() {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function() {
            showConsentModal();
        });
    }
};

'use strict';

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @param {jQuery.object} context - the context of fields to validate.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event, context) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        var context = context || $(this);
        $(this)
            .find('input, select')
            .each(function() {
                if ($(this).closest(context).length) {
                    if (!this.validity.valid) {
                        valid = false;
                        $(this).trigger('invalid', this.validity);
                    }
                }
            });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form)
        .find('.form-control.is-invalid')
        .removeClass('is-invalid');
    $(form)
        .find('.form-control.is-invalid')
        .removeAttr('aria-invalid');
    $(form).removeClass('is-invalid');
}

/**
 * Remove validation on form-field.
 * @param {element} $this - Form field to be cleared
 * @returns {void}
 */
function clearFormField($this) {
    $this
        .removeClass('is-invalid')
        .removeAttr('aria-invalid');
    $this
        .parents('.form-group')
        .find('.invalid-feedback')
        .text('');
}

module.exports = {
    invalid: function() {
        $('form input, form select').on('invalid', function(e) {
            e.preventDefault();
            this.setCustomValidity('');
            var invalid = false;
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this)
                    .addClass('is-invalid')
                    .attr('aria-invalid', 'true');
                if ($(this).data('invalid-error')) {
                    validationMessage = $(this).data('invalid-error');
                }
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this)
                    .parents('.form-group')
                    .find('.invalid-feedback')
                    .text(validationMessage);
                $(this)
                    .closest('form')
                    .find('.is-invalid')[0]
                    .focus();

                invalid = true;
            }

            if ($(this).closest('#footer-gated-email-popup').length > 0) {
                if (invalid && $(this).closest('form').find('.form-control').length - 1 === $(this).index()) {
                    var $form = $(this).closest('form');
                    $form.addClass('is-invalid');
                    $form.trigger('form:validateInvalid');
                }
            }
        });
    },

    submit: function() {
        var result;
        $('form').on('submit', function(e, data) {
            if (data && data.context) {
                result = validateForm.call(this, e, data.context);
            } else {
                result = validateForm.call(this, e);
            }
            return result;
        });
    },

    onGiftCardMsgInput: function() {
        $('.section--giftmsg textarea').on('input', function() {
            var $this = $(this);
            if (this.textLength === 60) {
                var validationMessage = $(this).data('limit-error');
                $this
                    .addClass('is-invalid')
                    .attr('aria-invalid', 'true');
                $this
                    .parents('.form-group')
                    .find('.invalid-feedback')
                    .text(validationMessage);
                
                setTimeout(() => {
                    clearFormField($this);
                }, 2000)
            } else {
                if ($this.hasClass('is-invalid')) {
                    clearFormField($this);
                }
            }
        });
    },

    buttonClick: function() {
        $('form button[type="submit"], form input[type="submit"]').on('click', function() {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    formCustomError: function () {
        $('form[name="dwfrm_footerSignUp"]').on('form:validateInvalid', function () {
            var $invalidFeedback = $(this).find('.form-invalid.invalid-feedback');
            var $footerSignUpEmail = $(this).find('input[name="dwfrm_footerSignUp_email"]');
            var $footerSignUpEmailConfirm = $(this).find('input[name="dwfrm_footerSignUp_emailconfirm"]');
            if ($footerSignUpEmail.length && $footerSignUpEmailConfirm.length && $footerSignUpEmail.val().length === 0
                || $footerSignUpEmailConfirm.val().length == 0 || $footerSignUpEmail.val() !== $footerSignUpEmailConfirm.val()) {
                if ($invalidFeedback.length > 0) {
                    $invalidFeedback.show();
                }
            }
        });
    },

    functions: {
        validateForm: function(form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};

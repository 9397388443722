'use strict';

var getCreativeIDs = function () {
    const sitePrefs = window.SitePreferences || {};
    let attentiveCreativeIDs = sitePrefs.ATTENTIVE_CREATIVE_IDS || [];

    if (typeof attentiveCreativeIDs !== 'undefined' &&  Object.keys(attentiveCreativeIDs).length > 0) {
        attentiveCreativeIDs = JSON.parse(attentiveCreativeIDs);
    }

    return attentiveCreativeIDs;
};

var getSignupUnitsByCountry = function (country) {
    var creativeIDs = getCreativeIDs();

    var signupUnits = creativeIDs.filter(function(item, i) {
        if (country && item.country === country) {
            return item.signupUnits;
        }
    }).map(function(item) {
        return item.signupUnits;
    });

    return signupUnits;
}

module.exports = {
    getCreativeIDs: getCreativeIDs,
    getSignupUnitsByCountry: getSignupUnitsByCountry
}
'use strict';

var formValidation = require('../components/formValidation');
var csrf = require('../csrf');
var domUtil = require('../domUtil');
var customUtil = require('../customUtil');
const attentive = require('./attentive');

$(document).ready(function () {
    if ($('#subscribeBday').length > 0 ) {
        $('#subscribeBday').datepicker({
            endDate: '0d',
            datesDisabled: '+1d',
            format: 'dd/mm'
        });
    }

    setIFrameTitleAttribute();
});

$(window).on('load', () => {
    //add alt text to all SVG elements programmatically
    const $svgElement = $('svg');

    if ($svgElement.length > 0) {
        $svgElement.each(function() {
            let $this = $(this),
                id = $this.attr('id') || $this.find('title').text() || $this.attr('aria-labelledby'),
                $parentEl = $this.parents('svg'),
                props = {
                    'aria-label': id,
                    'alt': id
                };
            const SVG_CONTAINER = 'svg container';

                $this.attr(props);

                if ($parentEl.length > 0) {
                    props['aria-label'] = SVG_CONTAINER;
                    props['alt'] = SVG_CONTAINER;
                    props['aria-hidden'] = true;
                    $parentEl.attr(props);
                }

                if ($this.find('use').length > 0) {
                    $this.find('use').attr(props);
                }
        });
    }

    setIFrameTitleAttribute();
});

/**
 * Error message function
 * @param {Object} data - Error message to display
 */
function sendGridResponse(data, email, formData) {
    if (!data.subscribed) {
        $('.error-msg').remove();
        $('.email-subscribe').attr('disabled', 'disabled');
        $('.subscribe-desc').prepend("<p class='error-msg' role='alert'>" + data.message + '</p>');
    } else {
        switch (formData.name) {
            case 'gatedForm':
                var $subscribeSuccess = $('#subscribe-success-gated');
                $subscribeSuccess.modal('show');
                $('#footer-gated-email-popup').modal('hide');
                $('#footer-gated-email-popup input.form-control').val('');
                $subscribeSuccess.attr('data-redirect-url', formData.redirectUrl);
                $subscribeSuccess.attr('data-strict-gated-form', formData.isStrictGatedForm);
                break;
            default:
                //$('#subscribe-success').modal('show');
                $('.email-popup-message').show();
                $('#footer-email-popup input.form-control').val('');
                //close the email popup after thank you message is shown
                setTimeout(() => {
                    $('#footer-email-popup').modal('hide');
                }, 2000);
        }

        // added SFMC email collection
        if (typeof(_etmc) !== 'undefined') {
        	window.emailCollected = {status: true, email: email};
            _etmc.push(["setUserInfo", {"email": email}]);
        }
    }
}

function redirectOnClose (e) {
    if (
        $(e.target).is('#footer-gated-email-popup')
        || $(e.currentTarget).is('[data-dismiss="modal"]')
        && $(e.currentTarget).closest('#footer-gated-email-popup').length > 0
    ) {
        var $modal = $('#footer-gated-email-popup');
        var redirectOnCloseUrl = $modal.attr('data-last-visited-url');
        var onCloseRedirectId = $modal.attr('data-page-id');
        var entryPageUrl = $modal.attr('data-redirect-url');
        var formUrl = $modal.attr('data-gated-form-url');
        var isStrictGatedForm = $modal.attr('data-strict-gated-form');
        var cookieName = $modal.attr('data-cookie-name');
        // When form is closed in non-strict mode either rpass edirect url or page id to match the page node in gated signup form json settings in custom site preferences
        redirectOnCloseUrl = redirectOnCloseUrl.indexOf('?') > -1 ? redirectOnCloseUrl + '&onCloseRedirect=' + encodeURIComponent(entryPageUrl) : '?onCloseRedirect=' + encodeURIComponent(entryPageUrl);
        redirectOnCloseUrl = redirectOnCloseUrl + '&formUrl=' + encodeURIComponent(formUrl);
        redirectOnCloseUrl = redirectOnCloseUrl + '&onCloseRedirectId='  + encodeURIComponent(onCloseRedirectId);
        redirectOnCloseUrl = redirectOnCloseUrl + '&cookieName=' + encodeURIComponent(cookieName);

        $.ajax({
            url: redirectOnCloseUrl,
            dataType: 'json',
            success: function (data) {
                if (data.redirect) {
                    if (isStrictGatedForm === 'true') {
                        window.location.href = data.redirect;
                    }
                }
            },
            error: function () {
                window.location.href = $modal.attr('data-redirect-home-url');
            }
        });
    }
}

function redirectOkButton(e) {
    if (
        $(e.target).is('#subscribe-success-gated')
        || $(e.currentTarget).is('[data-dismiss="modal"]')
        && $(e.currentTarget).closest('#subscribe-success-gated').length > 0
    ) {
        var $modal = $('#subscribe-success-gated');
        if ($modal.attr('data-strict-gated-form') ===  'true') {
            window.location.href = $modal.attr('data-redirect-url');
        }
    }
}

$(document).ready(function() {
    $('form.footer-sign-up').on('footer-sign-up:error', function (e, data) {
        csrf.updateTokens(data);
    });

    $('form.footer-sign-up').on('submit', function (event) {
        event.preventDefault();
        var $form = $(this);
        var url = $form.attr('action');
        var position = $form.attr('data-position');

        var newUrl = domUtil.appendToUrl(url, { position: position });

        $form.spinner().start();
        $.ajax({
            url: newUrl,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                    $('.error-msg').remove();
                    if ('message' in data) {
                        $('.subscribe-desc').prepend("<p class='error-msg' role='alert'>" + data.message + '</p>');
                    }
                    $('form.footer-sign-up').trigger('footer-sign-up:error', data);
                } else {
                    sendGridResponse(data, $form.find('#subscribeEmail').val(), { name: 'emailOptInForm' });
                }
            },
            error: function () {
                $form.spinner().stop();
            }
        });
        return false;
    });

    /* trigger the SFMC tracking conversion */
    var searchParam = new URLSearchParams(window.location.search);

    /* check the current URL has the mandatory parameter */
    if (searchParam && searchParam.get('utm_source') === 'SFMC') {
        var dataLayer = window.dataLayer || [];

        dataLayer.push({
            'event': 'enhancedEcom Tracking Conversion'
        });
    }
});

$(document).ready(function() {

    $('form.footer-gated-sign-up').on('footer-gated-sign-up:error', function (e, data) {
        csrf.updateTokens(data);
    });

    $(document).on('submit', 'form.footer-gated-sign-up', function (event) {
        event.preventDefault();
        var $form = $(this);
        var url = $form.attr('action');
        var position = $form.attr('data-position');
        var pageId = $form.closest('#footer-gated-email-popup').attr('data-page-id');
        var cookieName = $form.closest('#footer-gated-email-popup').attr('data-cookie-name');
        var gatedPage = $form.closest('#footer-gated-email-popup').attr('data-gated-page');
        var tokenGenerator = $form.closest('#footer-gated-email-popup').attr('data-token-generator');

        var newUrl = domUtil.appendToUrl(url, { position: position, cookieName: cookieName, pageId: pageId, gatedPage: gatedPage });

         $form.spinner().start();
        $.ajax({
            url: newUrl,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                    $('.error-msg').remove();
                    if ('message' in data) {
                        $('.subscribe-desc').prepend("<p class='error-msg' role='alert'>" + data.message + '</p>');
                    }
                    $('form.footer-gated-sign-up').trigger('footer-gated-sign-up:error', data);

                    $form.addClass('is-invalid');
                    var $invalidFeedback = $form.find('.form-invalid.invalid-feedback');
                    var $footerSignUpEmail = $form.find('input[name="dwfrm_footerSignUp_email"]');
                    var $footerSignUpEmailConfirm = $form.find('input[name="dwfrm_footerSignUp_emailconfirm"]');

                    if ($footerSignUpEmail.length && $footerSignUpEmailConfirm.length
                        && $footerSignUpEmail.val().length == 0
                        || $footerSignUpEmailConfirm.val().length == 0
                        || $footerSignUpEmail.val() != $footerSignUpEmailConfirm.val())
                    {
                        if (data.genericError) {
                            if ($invalidFeedback.length == 0) {
                                $invalidFeedback = $('<div class="form-invalid invalid-feedback">' + data.genericError + '</div>');
                                $form.prepend($invalidFeedback);
                            } else {
                                $invalidFeedback.html(data.genericError);
                                $invalidFeedback.show();
                            }
                        }
                    }

                } else {
                    var redirectUrl = window.location.href;
                    var isStrictGatedForm = $form.closest('#footer-gated-email-popup').attr('data-strict-gated-form');
                    sendGridResponse(data, $form.find('#subscribeEmailGated').val(), {name: 'gatedForm', redirectUrl: redirectUrl, isStrictGatedForm: isStrictGatedForm});
                }
            },
            error: function (e) {
                if (tokenGenerator) {
                    $.ajax({
                        url: tokenGenerator,
                        type: 'get',
                        async: false,
                        success: function (data) {
                            $('form.footer-gated-sign-up').trigger('footer-gated-sign-up:error', data);
                        }
                    });
                }
                $form.submit();
                window.console.error(e);
                $form.spinner().stop();
            }
        });
        return false;
    });
});

$('#footer-email-popup .form-control').change(function () {
    $('.email-subscribe').removeAttr('disabled');
});

$(document).on('show.bs.modal', '#footer-email-popup', function (event) {
    $('form.footer-sign-up')
        .find('.form-control.is-invalid')
        .removeClass('is-invalid');
    $('.error-msg').remove();

    const button = $(event.relatedTarget);
    const modal = $(this);
    modal.find('.footer-sign-up').attr('data-position', button.data('position'));
}).on('hide.bs.modal', '#footer-email-popup', () => {
    //hide the thank you message when email opt-in popup is closed
    $('.email-popup-message').hide();
});

$(document).on('show.bs.modal', '#footer-gated-email-popup', function (event) {
    $('form.footer-gated-sign-up')
        .removeClass('is-invalid')
        .find('.form-control.is-invalid')
        .removeClass('is-invalid');
    $('.error-msg').remove();

    const button = $(event.relatedTarget);
    const modal = $(this);

    modal.find('.footer-gated-sign-upp').attr('data-position', button.data('position'));
    modal.find('[data-dismiss="modal"]').on('click.dismiss.bs.modal', redirectOnClose);
}).on('click.dismiss.bs.modal', redirectOkButton);

$(document).on('show.bs.modal', '#subscribe-success-gated', function (event) {

    const button = $(event.relatedTarget);
    const modal = $(this);

    modal.find('.footer-gated-sign-upp').attr('data-position', button.data('position'));
});

$(window).on('load', function() {
    if (customUtil.isMobile()) {
        setTimeout(function() {
            /* Overrides the zendesk chat button styling */
            const cssStyle = {
                border: '2px solid #fff',
                padding: '1rem'
            };

            const style = Object.keys(cssStyle).map((prop) => {
                return `${prop}: ${cssStyle[prop]}`.concat(' !important');
            }).join(';');

            $('#launcher').contents().find('button').attr('style', style);
        }, 300);
    }
});

function isURLIncludes(arr) {
    if (arr.length > 0) {
        const filteredArray = arr.filter(k => {
            const regex = new RegExp(k, 'gi');
            return window.location.href.match(regex);
        });

        return filteredArray.length > 0;
    }
    return false;
}

function isEmpty(val) {
    return !val || val.length === 0;
}

function isUndefined(val) {
    return typeof val === 'undefined';
}

//email opt-in lightbox delay scripts
$(document).ready(() => {
    const sitePref = SitePreferences || window.SitePreferences;
    const excludedPages = sitePref.EMAIL_OPT_IN_EXCLUDE_PAGES;

    //only show for international customers
    if (!sitePref.IS_ATTENTIVE_COUNTRY) {
        //prevent the lightbox delay scripts from executing into certain pages
        if (isEmpty(excludedPages) || (excludedPages && !isURLIncludes(excludedPages.split(',')))) {
            const emailOptInCookieExp = sitePref.EMAIL_OPT_IN_COOKIE_EXPIRATION;
            let emailOptInTimeDelay = customUtil.isMobile() ? sitePref.EMAIL_OPT_IN_MOBILE_TIME_DELAY : sitePref.EMAIL_OPT_IN_DESKTOP_TIME_DELAY;
            const emailOptInTimeDelayInSeconds = (1000 * parseInt(emailOptInTimeDelay));

            if (!customUtil.getCookie('emailOptInPopUp')) {
                setTimeout(() => {
                    if (customUtil.isMobile()) {
                        //prevent the email opt-in lightbox from showing on the 1st page load in mobile
                        if (!customUtil.getCookie('emailOptInMobilePageLoaded')) {
                            customUtil.setCookie('emailOptInMobilePageLoaded', 1, 1);
                            return;
                        }

                        $('#footer-email-popup').modal('show');
                    } else {
                        $('#footer-email-popup').modal('show');
                    }
                }, emailOptInTimeDelayInSeconds);
            }

            //set the email opt-in lightbox cookie expiration when the modal close
            $(document).on('hide.bs.modal', '#footer-email-popup', function (event) {
                customUtil.setCookie('emailOptInPopUp', 1, emailOptInCookieExp);
            });

            //set the email opt-in lightbox cookie expiration when the form has been submitted
            $('form.footer-sign-up').on('submit', () => {
                customUtil.setCookie('emailOptInPopUp', 1, emailOptInCookieExp);
            });
        }
    }

    $('body').on('click', '.js-email-signup', (ev) => {
        ev.preventDefault();
        const sitePrefs = window.SitePreferences || {};
        var country = sitePrefs.COUNTRY || 'US';
        var attentiveCreativeIDs = attentive.getSignupUnitsByCountry(country),
              type = customUtil.isMobile() ? 'mobile' : 'desktop',
              emailSignupPos = $(ev.currentTarget).data('position');

        if (Object.keys(attentiveCreativeIDs).length > 0 && !isUndefined(emailSignupPos)) {
            attentiveCreativeIDs = attentiveCreativeIDs[0];

            const creativeID = {
                "header":
                    {
                        "desktop": attentiveCreativeIDs.desktopMailID,
                        "mobile": attentiveCreativeIDs.mobileMailID
                    },
                "footer":
                    {
                        "desktop": attentiveCreativeIDs.desktopFooterID,
                        "mobile": attentiveCreativeIDs.mobileFooterID
                    },
                "submit":
                    {
                        "desktop": attentiveCreativeIDs.desktopFooterID,
                        "mobile": attentiveCreativeIDs.mobileFooterID
                    }
            };
            var creativeIDValue = creativeID[emailSignupPos.toLowerCase()][type];
            // Backup to Desktop FooterID if needed
            if (!creativeIDValue && attentiveCreativeIDs.desktopFooterID) {
                creativeIDValue = attentiveCreativeIDs.desktopFooterID;
            }
            window.__attentive.trigger(null, null, null, creativeIDValue);
        }
    });
});

function setIFrameTitleAttribute() {
    //query all iframe and set title attribute
    let $iframe = $('iframe');
    if ($iframe.length > 0) {
        $iframe.each(function() {
            let $this = $(this),
                title = $this.attr('id') || 'empty',
                props = {};

            if (typeof $this.attr('tabindex') === 'undefined'
                || (typeof $this.attr('tabindex') !== 'undefined' && Number($this.attr('tabindex')) === -1)) {
                props = {
                    'aria-hidden': true,
                    'width': 0,
                    'height': 0
                };
            }

            if (typeof $this.attr('title') === 'undefined') {
                props.title = title;
                $this.attr(props);
            }
        });
    }
}


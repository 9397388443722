exports.isMobile = function () {
    var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
    var idx = 0;
    var isMobile = false;
    var userAgent = navigator.userAgent.toLowerCase();

    while (mobileAgentHash[idx] && !isMobile) {
        isMobile = userAgent.indexOf(mobileAgentHash[idx]) >= 0;
        idx++;
    }
    return isMobile;
};

exports.isSafari = function () {
    return navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
};

exports.iosVersion = function () {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
    return null;
};

exports.getCookie = function (cookiename) {
    if (document.cookie.length > 0) {
        startC = document.cookie.indexOf(cookiename + '=');
        if (startC != -1) {
            startC += cookiename.length + 1;
            endC = document.cookie.indexOf(';', startC);
            if (endC == -1) endC = document.cookie.length;
            return decodeURI(document.cookie.substring(startC, endC));
        }
    }
    return null;
};

exports.setCookie = function (cookieName, cookieValue, nDays) {
    var today = new Date();
    var expire = new Date();
    if (nDays == null || nDays == 0) nDays = 1;
    expire.setTime(today.getTime() + 3600000 * 24 * nDays);
    document.cookie = cookieName + '=' + escape(cookieValue) + '; expires=' + expire.toGMTString() + '; path=/';
};

exports.isEmpty = function (val) {
    // remove whitespaces
    if (typeof val === 'string') {
        val = $.trim(val);
    }

    return !val || val.length === 0;
};

exports.removeQueryParam = function (url) {
    var urlparts = String(url).split('?');
    if (urlparts.length >= 2) {
        return urlparts[0];
    }
    return url;
};

exports.getUrlQueryParameterValue = function (queryParamName, url) {
    var urlQueryVariables = url.split('&');
    var currentParameter;

    for (var i = 0; i < urlQueryVariables.length; i++) {
        currentParameter = urlQueryVariables[i].split('=');

        if (currentParameter[0] === queryParamName) {
            if (currentParameter[1] === undefined) {
                return true;
            }
            var decodedString = decodeURIComponent(currentParameter[1]);
            if (decodedString.split('|').length > 0) {
                return decodedString.split('|').join('+').replace(/\s/g, '-').toLowerCase();
            }
            return decodedString.replace(/\s/g, '-').toLowerCase();
        }
    }
    return false;
};

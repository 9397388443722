'use strict';
var Cleave = require('cleave.js').default;
var domUtil = require('./domUtil');

$(document).ready(function () {
    attachEventHandlersOnInputs();
    $('body').on('click', '.zipcode, .zip-code-missing-note span', function () {
        $(this)
            .closest('.edd')
            .find('.update-zip')
            .toggleClass('show');
    });
    $('body').on('input', '.zip-input', function () {
        var postalCodeRegex = /^\d{5}$/;
        if (postalCodeRegex.test($(this).val())) {
            $(this)
                .closest('.edd')
                .find('.zip-submit')
                .removeAttr('disabled');
        } else {
            $(this)
                .closest('.edd')
                .find('.zip-submit')
                .attr('disabled', 'disabled');
        }
    });
    $('body').on('click', '.zip-submit', function (event) {
        event.preventDefault();
        $.spinner().start();
        var zipCode = $(this)
            .closest('.edd')
            .find('.zip-input')
            .val();
        var url = $(this).data(url);
        var csrftoken = $('.zipupdate-form').find('input[name=csrf_token]').val();
        var invalidZipCodes = SitePreferences.INVALID_ZIP_CODES;
        var zipCodeFormErrorMsg = $('.zipupdate-form + .error');

        var $eddText = $(this).closest('.edd').find('.eddtext');
        if($eddText.length>0 && $eddText.hasClass('hide')){
            $eddText.removeClass('hide');
        }

        if($('.edd-cart').length>0 || $('.eddtext.minicart').length>0 || $('.eddtext.checkout').length>0){
            //var $eddZipCode = $(this).closest('.edd').find('.zipcode');
            if($('.zipcode.hide').length>0){
                $('.zipcode.hide').removeClass('hide');
            }
            if($('.eddtext.hide').length>0){
                $('.eddtext.hide').removeClass('hide');
            }
        }

        $('.zip-code-missing-note').addClass('hide');

        //check if entered zip code is valid/allowed
        if (invalidZipCodes && invalidZipCodes.split(',').indexOf(zipCode) > -1) {
            $('.zipupdate-form + .error').removeClass('hide');
            $.spinner().stop();
            return;
        }

        //hide error message when zip code is valid
        zipCodeFormErrorMsg.addClass('hide');

        /* Update the zip code for all the narvar element */
        $('[data-narvar-feature="getEdd"]').attr('data-narvar-dest-zip', zipCode);
        $('.update-zip').removeClass('show');

        $('.update-edd').each(function () {
            $(this)
                .find('.zipcode')
                .text(zipCode);
        });

        var formData;
        if ($('.shipping-form').length) {
            formData = $('.shipping-form').serialize();
        } else {
            formData = {
                'csrf_token': $('.zipupdate-form').find('input[name=csrf_token]').val()
            };
        }

        const $eddpid = $eddText.find('.eddpid');

        const urlParams = {
            zipCode: zipCode,
            country: $eddpid.data('narvarDestCountry'),
            category: $eddpid.data('narvarCategory'),
            carrierCode: $eddpid.data('narvarCarrierCode'),
            serviceCode: $eddpid.data('narvarServiceCode'),
            inStockDate: $eddpid.data('narvarInstockDate')
        }

        const newURL = domUtil.appendToUrl(url.url, urlParams);

        $.ajax({
            url: newURL,
            type: 'post',
            dataType: 'json',
            data: formData,
            success: function (res) {
                $eddpid.html(res.earliestDate);
                $eddText.removeClass('d-none');
            },
            error: function () {}
        });
        $.spinner().stop();
    });
});

/**
 * Attaches event handlers on input elements
 */
function attachEventHandlersOnInputs() {
    var cleaveCommonConfig = {
        numeral: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
        numeralPositiveOnly: true,
        stripLeadingZeroes: false
    };

    var zipCodeInput = {};
    $('.zip-code-field').toArray().forEach(function (field) {
        $.extend(zipCodeInput, cleaveCommonConfig, { numeralIntegerScale: 5 }); // Take up to 5 digits
        // eslint-disable-next-line no-new
        new Cleave(field, zipCodeInput);
    });
}

$('body').on('product:updateAvailability', function (e, data) {
    $('.eddpid')
        .attr('data-narvar-product-ids', data.product.id)
        .data('narvar-product-ids', data.product.id)
        .attr('data-narvar-instock-date', data.product.eddPreOrderDate ? data.product.eddPreOrderDate : '')
        .data('narvar-instock-date', data.product.eddPreOrderDate ? data.product.eddPreOrderDate : '');
});

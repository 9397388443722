'use strict';

var base = require('../product/base');
var focusHelper = require('base/components/focus');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl +=
        (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params)
            .map(function(key) {
                return key + '=' + encodeURIComponent(params[key]);
            })
            .join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml =
                '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' +
                data.valid.message +
                '</div>';

            $('.cart-error').html(errorHtml);
        } else {
            $('.cart')
                .empty()
                .append('<div class="row"> ' + '<div class="col-12 text-center"> ' + '<h1>' + data.resources.emptyCartMsg + '</h1> ' + '</div> ' + '</div>');
            $('.number-of-items')
                .empty()
                .append(data.resources.numberOfItems);
            $('.minicart-quantity')
                .empty()
                .append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * Update EDD of cart products
 * @param {Object} data - ajax response from the server
 */
function updateEdd(data) {
    var $estimateDeliveryDateData = $('.estimateDeliveryDateData');
    $estimateDeliveryDateData.each(function(idx) {
        try {
            var eddData = JSON.parse($(this).val());
            if (data.shipments) {
                for (var i = 0; i < data.shipments.length; i++) {
                    var shipment = data.shipments[i];
                    if (shipment.shippingMethods) {
                        for (var j = 0; j < shipment.shippingMethods.length; j++) {
                            var shippingMethod = shipment.shippingMethods[j];
                            if (shippingMethod.selected && eddData.carrierCode.toLowerCase() == shippingMethod.narvarCarrierCode.toLowerCase()) {
                                eddData.serviceCode = shippingMethod.narvarServiceCode || 'FG';
                                break;
                            }
                        }
                    }
                }
            }
            $(this).val(JSON.stringify(eddData));
        } catch (e) {
            return false;
        }
        var limit = 0;
        var timeInt;
        var $eddDataParent = $(this).closest('.edd');
        var unHideEdd = function() {
            if (
                $eddDataParent
                    .find('.eddpid')
                    .text()
                    .trim().length > 0 ||
                limit > 100
            ) {
                if (
                    $eddDataParent
                        .find('.eddpid')
                        .text()
                        .trim().length > 0
                ) {
                    $eddDataParent
                        .find('.eddpid')
                        .closest('.eddtext.hide')
                        .removeClass('hide');
                    $eddDataParent.removeClass('d-none');
                }
                clearTimeout(timeInt);
            } else {
                limit++;
                timeInt = setTimeout(unHideEdd, 100);
            }
        };
        unHideEdd();
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items')
        .empty()
        .append(data.resources.numberOfItems);
    var $shippingCost = $('.shipping-cost', '.order-total-summary, .order-totals-container');
    $shippingCost.empty();
    if (data.totals.totalShippingCostValue !== 0) {
        $shippingCost.removeClass('shipping-free').append(data.totals.totalShippingCost);
    } else {
        $shippingCost.addClass('shipping-free').append(data.resources.freeShipping);
    }
    $('.tax-total')
        .empty()
        .append(data.totals.totalTax);
    $('.grand-total .grand-total-sum')
        .empty()
        .append(data.totals.grandTotal);
    $('.sub-total')
        .empty()
        .append(data.totals.subTotal);
    $('.minicart-quantity')
        .empty()
        .append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems
    });
    $('.order-discount')
        .empty()
        .append(data.orderLevelAdjustmentsHtml);

    // Affirm SI-1635
    var totalCalculated = data.totals.grandTotal.substr(1).replace(/,/g, '');
    $('.affirm-as-low-as').attr('data-amount', (totalCalculated * 100).toFixed());
    //    var isWithinAffirmLimit = (parseFloat(totalCalculated) >= parseFloat(affirmLimits.min) && parseFloat(totalCalculated) <= parseFloat(affirmLimits.max))
    //    if (isWithinAffirmLimit) {
    //        $('#js-affirm-checkout-now').show();
    //    } else {
    //        $('#js-affirm-checkout-now').hide();
    //      $('.affirm-as-low-as').attr('data-amount', NaN);
    //    }
    // affirm.ui.refresh();

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total')
            .empty()
            .append('- ' + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function(item) {
        if (item.renderedPromotions) {
            $('.item-' + item.UUID)
                .empty()
                .append(item.renderedPromotions);
        }

        if (item.orderAddedPromotionsHtml && item.renderedPromotions) {
            $('.item-' + item.UUID).append(item.orderAddedPromotionsHtml);
        }

        if (item.orderAddedPromotionsHtml && !item.renderedPromotions) {
            $('.item-' + item.UUID)
                .empty()
                .append(item.orderAddedPromotionsHtml);
        }

        if (!item.orderAddedPromotionsHtml && !item.renderedPromotions) {
            $('.item-' + item.UUID).empty();
        }

        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $('.item-total-' + item.UUID)
                .empty()
                .append(item.priceTotal.renderedPrice);
        }

        if (item.orderAddedAdjustedPrice) {
            $('.item-total-' + item.UUID)
                .empty()
                .append(item.orderAddedAdjustedPrice.orderProratedPricehtml);
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml =
        '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        message +
        '</div>';

    $('.cart-error').html(errorHtml);
}

/**
 * re-renders the order summary details in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateOrderSummary(data) {
    if (data && data.totals) {
        const $el = $('.cart-page .order-summary');
        $el.find('.cart-subTotal .os-item-value span').text(data.totals.subTotal);
        $el.find('.order-discount').html(data.orderLevelAdjustmentsHtml);
        $el.find('.cart-shipping-cost .os-item-value span').text(
            data.totals.totalShippingCostValue !== 0 ? data.totals.totalShippingCost : data.resources.freeShipping
        );
        $el.find('.cart-sales-tax .os-item-value span').text(data.totals.totalTax);
        $el.find('.cart-total .os-item-value span').text(data.totals.grandTotal);
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function(item) {
            var img = item.discountImg && item.discountImg.hasOwnProperty('hi-res') ? item.discountImg['hi-res'] : null;
            var imgCont =
                img && img.length > 0
                    ? `<div class="approaching-discount-swatch-image col-3 text-right">
                            <img src="${img[0].url}" alt="${img[0].alt}" title="${img[0].title}" class="w-50" />
                        </div>`
                    : '';

            html += `<div class="approaching-discount row align-items-center py-2">
                        ${imgCont}
                        <div class="approaching-discount-message col-9">
                            ${item.discountMsg}
                        </div>
                    </div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem = {};
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function(message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">' + lineItem.availability.inStockDate + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function(item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function(attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function(attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + '.bfx-product';
        var image = $(imageSelector).find('img');
        var imageUrl = lineItem.images.small[0].url.replace('sw=100', 'sw=344&sh=460');
        $(image).attr('src', imageUrl);
        $(image).attr('alt', lineItem.images.small[0].alt);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).data('value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var qtySelector = '.custom-qty-spinner[data-uuid="' + uuid + '"]';
    $(qtySelector)
        .find('.value')
        .text(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade modal-center editProductModal" id="editProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body custom-scrollbar"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/** Changing aria-check on color click*/
function changeColor() {
    $('#editProductModal .color-attribute').attr('aria-checked', 'false');
    setTimeout(function() {
        $('#editProductModal .swatch-circle').each(function(i, d) {
            if ($(d).hasClass('selected')) {
                $(d)
                    .closest('.color-attribute')
                    .attr('aria-checked', 'true');
            }
        });
    }, 2000);
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body')
        .spinner()
        .start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');

            setTimeout(function() {
                $('.color-swatch-wrapper .color-attribute')
                    .off()
                    .on('click', changeColor);
                $('#editProductModal .swatch-circle').each(function(i, d) {
                    if ($(d).hasClass('selected')) {
                        var swatchVal = $(d).data('attr-value');
                        $('.selected-color').text(swatchVal.toLowerCase());
                    }
                });
            }, 1000);
            $.spinner().stop();
        },
        error: function() {
            $.spinner().stop();
        }
    });
}

/* Remove product ajax call */
/**
 * replace content of modal
 * @param {string} url - url
 * @param {string} uuid - url
 */
function removeProductService(url, uuid) {
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function(data) {
            var productDetails = data.productData;
            $('body')
                .trigger('updateGTM', productDetails)
                .trigger('gtm:product:removeFromCart', data);
            if (data.basket.items.length === 0) {
                $('.cart')
                    .empty()
                    .append(
                        '<div class="row"> ' +
                            '<div class="col-12 text-center"> ' +
                            '<h1>' +
                            data.basket.resources.emptyCartMsg +
                            '</h1> ' +
                            '</div> ' +
                            '</div>'
                    );
                $('.number-of-items')
                    .empty()
                    .append(data.basket.resources.numberOfItems);
                $('.minicart-quantity')
                    .empty()
                    .append(data.basket.numItems);
                $('.minicart-link').attr({
                    'aria-label': data.basket.resources.minicartCountOfItems
                });
                $('.minicart .popover').empty();
                $('.minicart .popover').removeClass('show');
                $('body').removeClass('modal-open');
                $('html').removeClass('veiled');
            } else {
                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                        $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                    }
                }
                $('.uuid-' + uuid).remove();
                if (!data.basket.hasBonusProduct) {
                    $('.bonus-product').remove();
                }
                $('.coupons-and-promos')
                    .empty()
                    .append(data.basket.totals.discountsHtml);
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                $('body').trigger('setShippingMethodSelection', data.basket);
                validateBasket(data.basket);
            }

            $('body').trigger('cart:update', data.basket);

            $.spinner().stop();
        },
        error: function(err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

function getEstimatedDeliveryDate() {
    var $eddElem = $('.estimateDeliveryDateData');
    // TODO add loop eddElem
    if ($eddElem.length) {
        $eddElem.each(function(e) {
            var $this = $(this);
            var url = $this.data('url');
            var val = $this.val();
            var uuid = $this.data('uuid');
            var $eddText = $this.closest('.js-edd-container.uuid-' + uuid);
            var $eddPidDiv = $eddText.find('.js-edd-request.uuid-' + uuid);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: { eddObj: val },
                success: function(data) {
                    if (data.success) {
                        var serviceCode;
                        var carrierCode;
                        try {
                            serviceCode = JSON.parse(val).serviceCode;
                            carrierCode = JSON.parse(val).carrierCode;
                        } catch (e) {
                            serviceCode = 'FG';
                        }
                        $eddPidDiv
                            .text('')
                            .attr('data-narvar-service-code', serviceCode)
                            .attr('data-narvar-carrier-code', carrierCode);
                        if (window.narvar) {
                            window.narvar('getEdd', {});
                            var limit = 0;
                            var timeInt;
                            var unHideEdd = function() {
                                if ($eddPidDiv.text().trim().length > 0 || limit > 100) {
                                    if ($eddPidDiv.text().trim().length > 0) {
                                        $eddPidDiv.closest('.eddtext.hide').removeClass('hide');
                                        $eddText.removeClass('d-none');
                                    }
                                    clearTimeout(timeInt);
                                } else {
                                    limit++;
                                    timeInt = setTimeout(unHideEdd, 100);
                                }
                            };
                            unHideEdd();
                        }
                    }
                }
            });
        });
    }
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

var onLoadInitEvents = function() {
    getEstimatedDeliveryDate();
    $('body').trigger('gtm:cartLoaded');
};

module.exports = function() {
    $('body').on('click', '.remove-product', function(e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function(e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });
    $('body').on('afterMovedtoWishlist', function(e, data) {
        e.preventDefault();
        var productID = data.productID;
        var url = data.actionUrl;
        var uuid = data.uuid;
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);
        removeProductService(url, uuid);
    });

    $('.optional-promo').click(function(e) {
        e.preventDefault();
        $(this).toggleClass('open');
        $('.promo-code-form').slideToggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function(e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        removeProductService(url, uuid);
    });

    function populateGTMData(productDetails, amount) {
        var gtmData = [
            {
                name: productDetails.name,
                id: productDetails.variationgroupid,
                variationgroupid: productDetails.variationgroupid,
                variantid: productDetails.variantid,
                price: productDetails.price,
                brand: productDetails.brand,
                variant: productDetails.color,
                color: productDetails.color,
                category: productDetails.category,
                quantity: amount < 0 ? Math.abs(amount) : amount,
                size: productDetails.size,
                dimension9: productDetails.size,
                season: productDetails.season,
                dimension10: productDetails.season
            }
        ];
        return gtmData;
    }

    function changeSpinnerValue($spinner, amount) {
        var $dropdown = $spinner.prev().find('select'),
            $spinnerVal = $spinner.find('.value'),
            newVal = parseInt($dropdown.val(), 10) + amount,
            minVal = parseInt($spinner.attr('data-min-qty'), 10),
            maxVal = parseInt($spinner.attr('data-max-qty'), 10);

        // If the new value is within the min and max values,
        // change the spinner display value and the associated dropdown's value
        if (newVal >= minVal && newVal <= maxVal) {
            $spinnerVal.text(newVal);

            var preSelectQty = $spinner.data('pre-select-qty');
            var quantity = newVal;
            var productID = $spinner.data('pid');
            var url = $spinner.data('action');
            var uuid = $spinner.data('uuid');

            var urlParams = {
                pid: productID,
                quantity: quantity,
                uuid: uuid
            };
            url = appendToUrl(url, urlParams);

            $spinner
                .parents('.card')
                .spinner()
                .start();

            $.ajax({
                url: url,
                type: 'get',
                context: this,
                dataType: 'json',
                success: function(data) {
                    var productDetails = data.productData;
                    if (productDetails) {
                        var actionField = (window.prodImpression && window.prodImpression.list) || '';
                        if (amount > 0) {
                            dataLayer.push({
                                event: 'enhanceEcom Product Add to Cart',
                                ecommerce: {
                                    currency: productDetails.currencyCode,
                                    add: {
                                        actionField: { list: actionField },
                                        products: populateGTMData(productDetails, amount)
                                    }
                                }
                            });
                        } else {
                            dataLayer.push({
                                event: 'enhanceEcom Product Remove From Cart',
                                ecommerce: {
                                    currency: productDetails.currencyCode,
                                    remove: {
                                        actionField: { list: actionField },
                                        products: populateGTMData(productDetails, amount)
                                    }
                                }
                            });
                        }
                    }
                    $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    $('body').trigger('Cart:ContentRefresh');
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    updateAvailability(data, uuid);
                    updateOrderSummary(data);
                    validateBasket(data);
                    $spinner.data('pre-select-qty', quantity);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                    if ($spinner.parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                        location.reload();
                    }
                },
                error: function(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $spinner.val(parseInt(preSelectQty, 10));
                        $.spinner().stop();
                    }
                }
            });
        }
    }

    $('body').on('click', '.icon-decrement', function(e) {
        e.preventDefault();
        changeSpinnerValue($(this).parent(), -1);
    });

    $('body').on('click', '.icon-increment', function(e) {
        e.preventDefault();
        changeSpinnerValue($(this).parent(), 1);
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this)
            .parents('.card')
            .spinner()
            .start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function(data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos')
                    .empty()
                    .append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $.spinner().stop();
                if (
                    $(this)
                        .parents('.product-info')
                        .hasClass('bonus-product-line-item') &&
                    $('.cart-page').length
                ) {
                    location.reload();
                }
            },
            error: function(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function() {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this)
                .find(':selected')
                .attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals')
            .spinner()
            .start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function(data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    updateEdd(data);
                    getEstimatedDeliveryDate();
                    $('body').trigger('cart:update', data);
                }
                $.spinner().stop();
            },
            error: function(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function(e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').hide();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control')
                .addClass('is-invalid')
                .focus();
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        var dataLayer = window.dataLayer || [];
        var couponCode = $('.coupon-code-field').val();

        var gtmEvent = {
            event: 'e_checkoutInteraction',
            eventCategory: 'checkout interaction',
            eventAction: 'promotional code applied'
        };

        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').hide();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function(data) {
                if (data.error) {
                    $('.promo-code-form .form-control')
                        .addClass('is-invalid')
                        .focus();
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message')
                        .show()
                        .html(data.errorMessage);

                    gtmEvent.eventLabel = `${couponCode} : ${data.errorMessage}`;
                } else {
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    $('body').trigger('cart:update', data);
                    $('body').trigger('Cart:ContentRefresh');
                    updateApproachingDiscounts(data.approachingDiscounts);
                    updateOrderSummary(data);
                    validateBasket(data);

                    gtmEvent.eventLabel = `${couponCode} : success`;
                }
                $('.coupon-code-field').val('');
                dataLayer.push(gtmEvent);
                $.spinner().stop();
            },
            error: function(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function(e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function(e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function(data) {
                $('body').trigger('Cart:ContentRefresh');
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                $('body').trigger('cart:update', data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateOrderSummary(data);
                validateBasket(data);
                $.spinner().stop();
            },
            error: function(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function() {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function(data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function() {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function() {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function(e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function() {
        $('#editProductModal')
            .siblings()
            .attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function() {
        $('#editProductModal')
            .siblings()
            .attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function(e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function(e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order') || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function(e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);
        var ele = $(e.target).find('.product-description');
        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog)
                .toArray()
                .every(function(item) {
                    return $(item).data('available');
                });

            var allReady = $('.product-availability', dialog)
                .toArray()
                .every(function(item) {
                    return $(item).data('ready-to-order');
                });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog)
                .empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
        if (response.product.variationAttributes && response.product.variationAttributes[1]) {
            var output = [];
            output.push('<option value="" data-attr-value="" data-value-id="">Select a size</option>');
            $.each(response.product.variationAttributes[1].values, function(key, value) {
                output.push(`<option value="${value.url}" data-attr-value="${value.value}" data-value-id="${value.value}"
                ${!value.selectable ? 'disabled' : ''} ${value.selected ? 'selected' : ''}>
                ${value.displayValue}  ${!value.selectable ? '- Currently Unavailable' : ''}
              </option>`);
            });

            ele.find('.custom-select.select-size').html(output.join(''));
        }
        $(ele)
            .find('.color-value')
            .each(function(d, e) {
                if ($(e).hasClass('unselectable')) {
                    $(e)
                        .parent()
                        .attr('disabled', true);
                } else {
                    $(e)
                        .parent()
                        .attr('disabled', false);
                }
            });
        $(ele)
            .find('.color-value')
            .parent('.color-attribute')
            .attr('aria-checked', 'false');
        $(ele)
            .find('.color-value.selected')
            .parent('.color-attribute')
            .attr('aria-checked', 'true');
        $('.update-cart-product-global').prop('disabled', false);
    });

    $('body').on('product:afterAttributeSelect', function(e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show')
                .find(response.container)
                .data('pid', response.data.product.id);
            $('.modal.show')
                .find(response.container)
                .find('.product-id')
                .text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function() {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);

        var selectedQuantityOption = $(this).find('option:selected');
        selectedQuantityOption.attr('selected', selectedQuantity);

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        base.attributeSelect(selectedQuantityOption.attr('data-url'), $productContainer, 'quantity');
    });

    $('body').on('click', '.custom-select-wrapper .size-attribute', function() {
        $('.update-cart-product-global').prop('disabled', true);
        var selectedOptionValueId = $(this)
            .find('.size-value.selectable')
            .addClass('selected')
            .end()
            .siblings()
            .children()
            .removeClass('selected')
            .end()
            .end()
            .children('.selected')
            .data('attr-value');
        if ($(this).find('.size-value.selectable.selected').length) {
            $('.update-cart-product-global').prop('disabled', false);
        }
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function(e) {
        e.preventDefault();

        var updateProductUrl = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .val();
        var selectedQuantity = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('selected-quantity');
        var selectedOptionValueId = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('selected-option');
        var uuid = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('uuid');

        var form = {
            uuid: uuid,
            pid: $(this)
                .closest('.modal-content')
                .find('.product-quickview')
                .data('pid'),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this)
            .parents('.card')
            .spinner()
            .start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function(data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos')
                        .empty()
                        .append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data.cartModel);

                    $.spinner().stop();
                },
                error: function(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $(window).on('load', onLoadInitEvents);

    $('body').on('click', '[data-attr="size"] button', function(e) {
        e.preventDefault();

        if ($(this).closest('.plp-atc').length || $('[data-page="Product-IncludeShow"]').length) {
            return;
        }

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        base.attributeSelect($(this).attr('data-url'), $productContainer, 'size');
    });

    $('body').on('Cart:ContentRefresh', () => {
        const $cartPage = $('.cart-page');
        const url = $cartPage.data('refresh-url');

        if (url) {
            $.ajax({
                url: url,
                method: 'GET',
                success: res => {
                    const $targetContainer = $cartPage.find('.cart-products');
                    $targetContainer.empty();
                    $targetContainer.html(res);
                    //re-render the EDD content value
                    getEstimatedDeliveryDate();
                    $('body').trigger('Cart:ContentRefreshDone');
                }
            });
        }
    });

    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    updateEdd({});
};

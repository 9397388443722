var DEBUG = false;
if (!DEBUG) {
    if (!window.console) window.console = {};
    var methods = ['log', 'debug', 'warn', 'info'];
    for (var i = 0; i < methods.length; i++) {
        // eslint-disable-next-line no-console
        console[methods[i]] = function () {};
    }
}
window.onerror = function(){};
window.addEventListener('error', function(event){ return '';});
window.log = console.log.bind(console);
'use strict';

$('.content-page .expand-all').on('click', function() {
    $('.collapse').collapse('show');
    $('.collapsible-xs').addClass('active');
    $('.close-all').css('display', 'inline-block');
    $('.expand-all').hide();
});

$('.content-page .close-all').on('click', function() {
    $('.collapse').collapse('hide');
    $('.collapsible-xs').removeClass('active');
    $('.expand-all').css('display', 'inline-block');
    $('.close-all').hide();
});


'use strict';
module.exports = {
    updateTokens: function (data) {
        if (data && 'csrf' in data) {
            var csrf = data.csrf;
            if ('tokenName' in csrf && 'token' in csrf) {
                var $elem = $('input[name="' + csrf.tokenName + '"]');
                if ($elem.length > 0) {
                    $elem.each(function () {
                        $(this).val(csrf.token);
                    });
                }
            }
        }
    }
};

'use strict';

module.exports = function() {
    $('[data-toggle="tooltip"]').tooltip();

    $(".cvv-tooltip").tooltip({ 
        placement: 'right',
        html: true,
        title: '<span class="title-text">'+$('.cvv-tooltip').data('title')+'</span><span class="cvv"></span><span class="cvv amex"></span>'
    });
};

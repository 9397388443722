window.jQuery = window.$ = require('jquery');

require('./global/consoleLogDisabler');
require('slick-carousel/slick/slick');
require('./slickCarousel');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');
var processInclude = require('base/util');

$(document).ready(function() {
    processInclude(require('./search/categoryMarketingSlotTile'));
    processInclude(require('./global/focusHandler'));
    processInclude(require('base/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/header'));
    processInclude(require('./components/accordion-dropdown'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./modules/imageLazyLoad'));
    processInclude(require('./components/tooltip'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/zipCodeValidation'));
    processInclude(require('./contentPage'));
    processInclude(require('./hero'));
    processInclude(require('./edd'));
    processInclude(require('./gtm'));
});


require('base/thirdParty/bootstrap');
require('bootstrap/js/src/tooltip.js');
require('base/components/spinner');

'use strict';

module.exports = function () {
    var $accordion = $('.accordion-component');

    if ($accordion.hasClass('accordion-dropdown')) {
        $accordion.find('.accordion-body a').on('click', function (event) {
            event.preventDefault();

            var $selectedEl = $(this);
            // Toggle styles for selected list item in dropdown
            $selectedEl.closest('.accordion-dropdown').find('.list-group-item').removeClass('active');
            $selectedEl.closest('.list-group-item').addClass('active');

            // Update the selected header text
            var $headerEl = $selectedEl.closest('.accordion-component').find('.accordion-header .selected-value');
            $headerEl.text($selectedEl.text());

            // Trigger a event with value clicked
            $selectedEl.closest('.accordion-dropdown').trigger('dropdownValueSelected', {
                value: $selectedEl.data('value')
            });

            // Collapse the accordion
            $selectedEl.closest('#accordionSection').collapse('hide');
        })
    }
};

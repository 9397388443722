'use strict';

$(document).ready(function() {
    //GTM code for promo impressions
    const $gtmPromo = $('.gtm-promo');
    if ($gtmPromo.length > 0) {
        $gtmPromo.find('a').on('click', function(ev) {
            ev.stopPropagation();
            let promotions = [];
            let skipThisPromo = false;

            const isSLider = $(this).parents('.slick-slide').length > 0;

            if (isSLider) {
                //Don't track duplicate (slick-cloned) promotions:
                var $parentSlide = $(this).parents('.slick-slide');
                if ($parentSlide.hasClass('slick-cloned') === true) skipThisPromo = true;
            }

            if (!skipThisPromo) {

                const $contentAsset = $(this).closest('.gtm-promo');
                const $imgElement = $(this).find('img') || $contentAsset.find('img');
                let creative = '';

                if ($imgElement.length > 0) {
                    creative = $imgElement.attr('src') || $imgElement.data('lazy');
                    const imgSrc = creative.split('/');
                    creative = imgSrc[imgSrc.length - 1];
                } else {
                    creative = $(this).attr('title');
                }

                promotions.push({
                    id: $contentAsset.data('id'),
                    name: $contentAsset.data('name'),
                    creative,
                    position: $contentAsset.data('position'),
                });

                if (promotions.length > 0) {
                    dataLayer.push({
                        event: 'enhanceEcom Promo Clicks',
                        ecommerce: {
                            promoClick: {
                                promotions: promotions
                            }
                        }
                    });
                }
            }

        });
    }

    //create GA/GTM event for post purchase interaction by adding 'gtm-post-purchase' class into the element
    const gtmPostPurchase = '.gtm-post-purchase';

    if ($(gtmPostPurchase).length > 0) {
        $(document).on('click', gtmPostPurchase, function() {
            var that = $(this);

            dataLayer.push({
                'event': 'e_postPurchaseInteraction',
                'eventCategory': 'post page interaction',
                'eventAction': that.data('event-action') ? that.data('event-action').toLowerCase() : '',
                'eventLabel': that.data('event-label')
            });
        });
    }

    // Navigation  - Header/Footer
    $('header').on('click', '.gtm-nav-link', function(){
       var dataLayer = window.dataLayer || [];
       var $menuItem = $(this);
       var $menuNav = $menuItem.closest('.nav-item.dropdown').length
            ? $menuItem.closest('.nav-item.dropdown').find('.gtm-nav-title').first()
            : $menuItem.closest('.nav-item').find('.gtm-nav-title').first();
       var menuName = 'Header';
       dataLayer.push({
          'event': 'e_navigation',
          'eventCategory': 'navigation',
          'eventAction': [menuName, $menuNav.text().trim().toLowerCase()].join(':'),
          'eventLabel': $menuItem.text().trim().toLowerCase()
       });
    });

    $('footer .menu-footer a, footer .footer-store-mobile a').click(function(){
        var dataLayer = window.dataLayer || [];
        var $menuItem = $(this);
        var $menuNav = $menuItem.closest('div').children('.title');
        var menuName = 'Footer';
        dataLayer.push({
            'event': 'e_navigation',
            'eventCategory': 'navigation',
            'eventAction': [menuName, $menuNav.text().trim().toLowerCase()].join(':'),
            'eventLabel': $menuItem.text().trim().toLowerCase()
        });
    });

    $('body')
        .on('gtm:product:afterAddToWishlist', function(e, data) {
            if (data.error) {
                return;
            }

            var productData = data.productData;

            if (!productData) {
                return;
            }

            var dataLayer = window.dataLayer || [];
            var dataLayerParams = {
                event: 'add_to_wishlist',
                currency: productData.currencyCode,
                value: productData.price,
                items: [productData]
            };

            dataLayer.push(dataLayerParams);
        }).on('gtm:product:removeFromCart', function(e, data) {
            if (data.error) {
                return;
            }

            var productData = data.productData;

            if (!productData) {
                return;
            }

            var dataLayer = window.dataLayer || [];
            var dataLayerParams = {
                event: 'remove_from_cart',
                currency: productData.currencyCode,
                value: productData.price,
                items: [productData]
            };

            dataLayer.push(dataLayerParams);
        }).on('gtm:cartLoaded', function() {
            var $source = $('.js_cart-product-data');

            if (!$source.length) {
                return;
            }

            var productData = JSON.parse($source.val());

            var dataLayer = window.dataLayer || [];
            var dataLayerParams = {
                event: 'view_cart',
                currency: $source.data('currency'),
                value: $source.data('total-price'),
                items: productData
            };

            dataLayer.push(dataLayerParams);
        });
});
